<template>
  <div>
    <v-divider></v-divider>
    <h3
      class="primary--text pt-6 pb-3 text-center"
    >
      {{ $t('result.nextTests') }}
    </h3>
    <v-container
      v-for="(test, index) in otherTests" :key="index"
    >
      <TestSelection
        :id="test"
        :key="`testSelection${index}`"
      />
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'MoreTests',
  components: {
    TestSelection: lazyLoad('components/Cards/TestSelection'),
  },
  computed: {
    ...mapGetters('testMacro', [
      'availableTests',
    ]),
    ...mapGetters('testMicro', [
      'id',
    ]),
    otherTests() {
      if (this.id === 'ressources') {
        return this.availableTests.filter((e) => e !== 'resources');
      }
      return this.availableTests.filter((e) => e !== this.id);
    },
  },
};
</script>
